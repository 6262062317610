@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .text-shadow {
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  }
  .text-shadow-lg {
    text-shadow: 3px 3px 6px rgba(0,0,0,0.5);
  }
}